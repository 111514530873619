<template>
  <div class="pa-2">
    <v-card>
      <v-row class="pa-2 flex-column flex-sm-row" dense>
        <v-col align-self="center">
          <div class="text-center text-uppercase">latest session</div>
        </v-col>

        <v-divider vertical inset class="mr-3"></v-divider>

        <v-col>
          <div class="d-flex flex-column text-capitalize">
            <div class="justify-cente">
              <p class="text-uppercase primary--text font-weight-bold">house</p>
              <div v-if="houseSessions.length < 1">No sessions to display</div>
              <div v-if="houseSessions.length > 0">
                <div class="d-flex">
                  <v-icon color="primary"
                    >{{
                      new Date(
                        `${houseSessions[0].date} ${houseSessions[0].time}`
                      ).getHours() > 12
                        ? "mdi-weather-sunny"
                        : "mdi-weather-partly-cloudy"
                    }}
                  </v-icon>
                  <p class="my-auto ml-2">
                    {{
                      new Date(
                        `${houseSessions[0].date} ${houseSessions[0].time}`
                      ).getHours() > 12
                        ? "afternoon session"
                        : "morning session"
                    }}
                  </p>
                </div>
                <div class="d-flex mt-2">
                  <v-icon color="primary">mdi-calendar-outline</v-icon>
                  <p class="my-auto ml-2">
                    {{ houseSessions[0].date | moment("ddd, MMMM Do YYYY") }}
                  </p>
                </div>
                <div class="d-flex mt-2">
                  <v-icon color="primary">mdi-clock-outline</v-icon>
                  <p class="my-auto ml-2">
                    {{
                      `${houseSessions[0].date} ${houseSessions[0].time}`
                        | moment("calendar")
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-col>

        <v-divider vertical inset class="mr-3"></v-divider>

        <v-col>
          <div class="d-flex flex-column text-capitalize">
            <div>
              <p class="text-uppercase green--text font-weight-bold">
                commitee
              </p>
              <div v-if="commiteeSessions.length < 1">
                No sessions to display
              </div>
              <div v-if="commiteeSessions.length > 0">
                <div class="d-flex">
                  <v-icon color="green"
                    >{{
                      new Date(
                        `${commiteeSessions[0].date} ${commiteeSessions[0].time}`
                      ).getHours() > 12
                        ? "mdi-weather-sunny"
                        : "mdi-weather-partly-cloudy"
                    }}
                  </v-icon>
                  <p class="my-auto ml-2">
                    {{
                      new Date(
                        `${commiteeSessions[0].date} ${commiteeSessions[0].time}`
                      ).getHours() > 12
                        ? "afternoon session"
                        : "morning session"
                    }}
                  </p>
                </div>
                <div class="d-flex mt-2">
                  <v-icon color="green">mdi-calendar-outline</v-icon>
                  <p class="my-auto ml-2">
                    {{ commiteeSessions[0].date | moment("ddd, MMMM Do YYYY") }}
                  </p>
                </div>
                <div class="d-flex mt-2">
                  <v-icon color="green">mdi-clock-outline</v-icon>
                  <p class="my-auto ml-2">
                    {{
                      `${commiteeSessions[0].date} ${commiteeSessions[0].time}`
                        | moment("calendar")
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-col>

        <v-divider vertical inset class="mr-3"></v-divider>

        <v-col>
          <div class="d-flex flex-column text-capitalize">
            <div>
              <p class="text-uppercase purple--text font-weight-bold">joint</p>
              <div v-if="jointSessions.length < 1">No sessions to display</div>
              <div v-if="jointSessions.length > 0">
                <div class="d-flex">
                  <v-icon color="purple">mdi-account-group</v-icon>
                  <p class="my-auto ml-2">
                    {{ jointSessions[0].committee.name }}
                  </p>
                </div>
                <div class="d-flex mt-2">
                  <v-icon color="purple">mdi-calendar-outline</v-icon>
                  <p class="my-auto ml-2">
                    {{ jointSessions[0].date | moment("ddd, MMMM Do YYYY") }}
                  </p>
                </div>
                <div class="d-flex mt-2">
                  <v-icon color="purple">mdi-clock-outline</v-icon>
                  <p class="my-auto ml-2">
                    {{
                      `${jointSessions[0].date} ${jointSessions[0].time}`
                        | moment("calendar")
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <div class="mx-auto mt-7 text-h5 font-weight-light text-capitalize">
        Approved legistlation - {{ new Date().getFullYear() }}
      </div>
    </v-row>

    <v-row class="justify-center">
      <v-col :cols="isMobile ? '12' : '4'">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: 'OrderPapers' }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="primary" rounded>
                      <v-icon x-large>mdi-note-edit</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column">
                      <div class="primary--text text-h6">
                        {{ orderPaperCount }}
                      </div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        Order Papers
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col :cols="isMobile ? '12' : '4'">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: 'BillsAdopted' }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="primary" rounded>
                      <v-icon x-large>mdi-note-check</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column">
                      <div class="primary--text text-h6">
                        {{ billCount }}
                      </div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        Bills
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col :cols="isMobile ? '12' : '4'">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: 'MotionsEnacted' }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="primary" rounded>
                      <v-icon x-large>mdi-script-text</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column">
                      <div class="primary--text text-h6">
                        {{ motionCount }}
                      </div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        motions
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col :cols="isMobile ? '12' : '4'">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: 'Petitions' }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="purple">
                      <v-icon x-large>mdi-note-text</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column pr-sm-0 pr-10">
                      <div class="purple--text text-h6">
                        {{ petitionCount }}
                      </div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        petitions
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col :cols="isMobile ? '12' : '4'">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: 'ActsAdopted' }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="purple">
                      <v-icon x-large>mdi-gavel</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column pr-sm-0 pr-10">
                      <div class="purple--text text-h6">
                        {{ actCount }}
                      </div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        acts
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col :cols="isMobile ? '12' : '4'">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: 'PapersLaid' }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="purple">
                      <v-icon x-large>mdi-note-multiple</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column pr-sm-0 pr-10">
                      <div class="purple--text text-h6">
                        {{ papersLaidCount }}
                      </div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        papers laid
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-row class="mt-3 flex-column flex-md-row">
      <v-col>
        <v-card>
          <v-card-title class="text-capitalize text-h5">
            communication from the chair
          </v-card-title>
          <v-card-text @click="chairDialog = true">
            <pdf-viewer :pdf="chairDoc"></pdf-viewer>
            <v-dialog v-model="chairDialog">
              <pdf-viewer :pdf="chairDoc"></pdf-viewer>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="text-capitalize text-h5">
            communication from the clerk
          </v-card-title>
          <v-card-text @click="clerkDialog = true">
            <pdf-viewer :pdf="clerkDoc"></pdf-viewer>
            <v-dialog v-model="clerkDialog">
              <pdf-viewer :pdf="clerkDoc"></pdf-viewer>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PdfViewer from "../components/PdfViewer.vue";
import { mapState, mapActions } from "pinia";
import { sessionStore } from "../stores/sessions";
import Api from "../services/Api";

export default {
  name: "Dashboard",
  data: () => ({
    chairDialog: false,
    clerkDialog: false,
    chairDoc: null,
    clerkDoc: null,
    fileCount: null,
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.xs;
    },
    ...mapState(sessionStore, [
      "sessions",
      "houseSessions",
      "commiteeSessions",
      "jointSessions",
    ]),
    // overview row 1
    orderPaperCount() {
      if (this.fileCount !== null)
        if (this.fileCount.some((file) => file.type == "order-papers"))
          return this.fileCount.find((f) => f.type == "order-papers").total;
        else return 0;
      else return 0;
    },
    billCount() {
      if (this.fileCount !== null)
        if (this.fileCount.some((file) => file.type == "bill"))
          return this.fileCount.find((f) => f.type == "bill").total;
        else return 0;
      else return 0;
    },
    motionCount() {
      if (this.fileCount !== null)
        if (this.fileCount.some((file) => file.type == "motion"))
          return this.fileCount.find((f) => f.type == "motion").total;
        else return 0;
      else return 0;
    },
    // overview row 2
    petitionCount() {
      if (this.fileCount !== null)
        if (this.fileCount.some((file) => file.type == "petitions"))
          return this.fileCount.find((f) => f.type == "petitions").total;
        else return 0;
      else return 0;
    },
    actCount() {
      if (this.fileCount !== null)
        if (this.fileCount.some((file) => file.type == "act"))
          return this.fileCount.find((f) => f.type == "act").total;
        else return 0;
      else return 0;
    },
    papersLaidCount() {
      if (this.fileCount !== null)
        if (this.fileCount.some((file) => file.type == "papers-laid"))
          return this.fileCount.find((f) => f.type == "papers-laid").total;
        else return 0;
      else return 0;
    },
  },
  components: {
    PdfViewer,
  },
  methods: {
    ...mapActions(sessionStore, ["getSessions"]),
  },
  created() {
    // get sessions to show upcoming meetings
    this.getSessions();
    // get number of files of each type
    Api.getFileCount().then((res) => {
      this.fileCount = res.data.data;
    });
    // get 'memos' from the clerk and chair
    Api.getSpecificFileType("communication-chair").then((res) => {
      this.chairDoc = res.data.data.data[0].location;
    });
    Api.getSpecificFileType("communication-clerk").then((res) => {
      this.clerkDoc = res.data.data.data[0].location;
    });
  },
};
</script>
